@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans%3A300%2C400%2C600%2C700%2C800%2C300italic%2C400italic%2C600italic%2C700italic%2C800italic%7CNunito%3A200%2C300%2C400%2C600%2C700%2C800%2C900%2C200italic%2C300italic%2C400italic%2C600italic%2C700italic%2C800italic%2C900italic%7CRoboto%3A100%2C300%2C400%2C500%2C700%2C900%2C100italic%2C300italic%2C400italic%2C500italic%2C700italic%2C900italic&ver=1723732623');

.center-items {
    @apply flex items-center justify-center;
}

.green-rounded-button {
    @apply bg-green rounded-[21px] px-6  h-[40px] center-items text-white font-bold disabled:bg-[#B9B9B8] min-w-[157px];
}

h2 {
    @apply text-[22px] md:text-[26px];
}

.h3 {
    @apply text-lg md:text-xl;
}

button:hover {
    @apply opacity-80;
}