.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p:has(> consent-check) {
  display: flex;
  align-items: center;
}

.imported-form consent-check {
  @apply flex justify-center items-center;
}

input[type="checkbox"] {
  width: 26px;
  height: 26px;
  border: 1px solid black;
  cursor: pointer;
  margin-right: 10px;
  margin: 3px 10px 3px 0;
}

.imported-form h1 {
  @apply text-4xl font-bold;
}

.imported-form p {
  @apply text-lg mt-2;
}
.imported-form {
  @apply flex flex-col p-8 !pb-10;
}

.imported-form consent-sign {
  @apply mt-4;
}

.simulation {
  @apply text-red-700 underline cursor-pointer mt-[18px];
}

h2 {
  @apply text-2xl font-medium;
}

.imported-form * {
  @apply font-sans;
}

.document-header * {
  @apply font-nunito;
}

.has-consent-sign {
  @apply w-full flex;
}

.has-consent-sign * {
  @apply flex flex-col w-full;
}

.has-consent-sign tr:last-child {
  @apply !flex-row;
}

/* .has-consent-sign tr:last-child td:last-child > span {
  @apply text-right;
} */

.first-table tr {
  border-bottom: 1px solid #ddd; /* Border for better separation */
}

/* Style for even rows */
.first-table tr:nth-child(even) {
  background-color: #f2f2f2; /* Light gray background for even rows */
}

/* Style for odd rows */
.first-table tr:nth-child(odd) {
  background-color: #ffffff; /* White background for odd rows */
}

.has-consent-check tr > td {
  @apply font-bold;
  font-size: 1.2rem;
  vertical-align: top;
}

h1 * {
  @apply !font-nunito !font-semibold leading-[1.4rem] antialiased;
  font-stretch: 100%;
  font-size-adjust: none;
  font-kerning: auto;
  font-optical-sizing: auto;
}

/* green line under h1 */
h1::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background-color: #84BC01;
  margin-top: 10px;
}

strong {
  @apply font-bold text-[15px];
}

p * {
  @apply text-base;
}

canvas {
  border: 1px solid black;
}